import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "src/app.module.css";
import ReactAPILinkHandler from "src/components/ReactAPILinkHandler/ReactAPILinkHandler";
import TelegramAPIHandler from "src/components/TelegramAPIHandler/TelegramAPIHandler";
import ReactAPIShareHandler from "src/components/ReactAPIShareHandler/ReactAPIShareHandler";
import ReactAPILinkButtonHandler from "src/components/ReactAPILinkButton/ReactAPILinkButtonHandler";
import React, { useEffect, useState } from 'react';

const appName = "Game"; //enter your app file name from public/unitybuild folder

const App = () => {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    initialisationError,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "/unitybuild/" + appName + ".loader.js",
    dataUrl: "/unitybuild/" + appName + ".data",
    frameworkUrl: "/unitybuild/" + appName + ".framework.js",
    codeUrl: "/unitybuild/" + appName + ".wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const [loadingTimerTriggered, setLoadingTimerTriggered] = useState(false);
  const [errorTimerTriggered, setErrorTimerTriggered] = useState(false);

  useEffect(() => {
    let loadingTimer: NodeJS.Timeout;
    let errorTimer: NodeJS.Timeout;

    if (!isLoaded && !loadingTimerTriggered) {
      loadingTimer = setTimeout(() => {
        window.location.reload();
      }, 30000);
      setLoadingTimerTriggered(true);
    }

    if (initialisationError != null && !errorTimerTriggered) {
      errorTimer = setTimeout(() => {
        window.location.reload();
      }, 3000);
      setErrorTimerTriggered(true);
    }

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(errorTimer);
    };
  }, [isLoaded, initialisationError, loadingTimerTriggered, errorTimerTriggered]);

  return (
    <div className={styles.container}>
      <div className={styles.unityWrapper}>
        {isLoaded === false && (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderWrapper}>
              {<img className="img" 
                    src="/images/logo.png" 
                    style={{ width: '300px', height: '300px' }}>
              </img>}
              {/* <img className="img" src="/images/animatedLogo.gif"></img> */}
  
              <div className={styles.loadingBar}>
              {<img className="loadingBg" 
                    src="/images/loader-background.png" 
                    style={{ width: '300px', height: '50px' , marginTop: '-29.1px' }}>
              </img>}
                <div className={styles.loadingBarFill}>
                <div className={styles.loadingbarfillBg}>
                      <img
                        src="/images/loader-image.png"
                        className={styles.loadingbarimg}
                        style={{ width: loadingProgression * 300 , marginLeft: '12px'}}
                      />
                    </div>
                </div>
              </div>
              <h1 className={styles.textBigger}>
                {Math.floor(loadingProgression * 100)}%
              </h1>
              <h1 className={styles.text}>
                {loadingProgression >= 0 && loadingProgression <= 0.25 && initialisationError == null && "Checking for updates"}
                {loadingProgression > 0.25 && loadingProgression <= 0.5 && initialisationError == null && "Loading game resources"}
                {loadingProgression > 0.5 && loadingProgression <= 0.75 && initialisationError == null && "Initializing"}
                {loadingProgression >= 0.75 && initialisationError == null && "Opening portal to the multiverse"}
                {initialisationError != null && "The portal failed to open, we will retry in 3 seconds"}
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </h1>
            </div>
          </div>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ display: isLoaded ? "block" : "none" }}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.center}>
          <ReactAPILinkButtonHandler
            addEventListener={addEventListener}
            removeEventListener={removeEventListener}
          />
        </div>
      </div>
      <ReactAPILinkHandler
        addEventListener={addEventListener}
        removeEventListener={removeEventListener}
      />
      <TelegramAPIHandler
        addEventListener={addEventListener}
        removeEventListener={removeEventListener}
      />
      <ReactAPIShareHandler
        addEventListener={addEventListener}
        removeEventListener={removeEventListener}
      />
    </div>
  );
};

export { App };
